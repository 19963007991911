#contact {
  display: flex;
  text-align: center;
  justify-content: space-around;
}

.contact {
  margin: 2em 0;
  max-width: 800px;
  display: flex;
  flex-direction: column;
}

legend {
  text-align: start;
}

.contactField {
  width: 65vw;
  max-width: 1000px;
  text-align: center;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--background);
  border-radius: 1em;
  padding: 1em;
  margin-bottom: 1em;
  box-shadow: 2rem 2rem 5rem rgba(0, 0, 0, 0.5);
}

.contactLabel {
  font-size: large;
  margin-left: 2em;
  cursor: text;
}

.contactInput {
  margin: 1.5em;
  min-width: 30vw;
  width: auto;
  font-size: medium;
  font-weight: 900;
  max-height: 15vh;
  background-color: var(--border);
  color: var(--text-secondary);
}

.contactInput:focus {
  outline: 2px solid var(--accent);
}

.contactButton {
  color: var(--accent);
  background-color: var(--text-secondary);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: large;
  letter-spacing: 0.05em;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid var(--border);
  padding: 5px 15px;
  box-shadow: 0 0 10px 1px var(--text-primary);
  cursor: pointer;
  margin: 0 0 15px;
  z-index: 500;
}

.contactButton:hover {
  color: var(--text-primary);
  background-color: var(--accent);
}

.contactLegend {
  color: var(--text-secondary);
}

textarea {
  resize: vertical;
}
