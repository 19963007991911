#about {
  display: flex;
  background-color: var(--background);
  text-align: center;
  justify-content: space-around;
  z-index: 2;
}

.about {
  padding-top: 3em;
  margin: 2em 0;
  /* max-width: 1200px; */
  /* width: 100%; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.abouthr {
  align-self: center;
}

p {
  margin: 0;
}

.hr {
  margin: 0 auto;
  margin-top: 0.5em;
  width: 100%;
  border: 0;
  height: 2px;
}

.hrwhite {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

.hrblack {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.timpar {
  padding: 0 2em;
  text-align: start;
  line-height: 1.5;
  letter-spacing: 0.05em;
  font-size: large;
  max-width: 1200px;
}

.margintop {
  margin: 2em 0;
}
