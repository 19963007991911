#toptwo {
  margin-top: 5em;
  display: flex;
  align-self: center;
  z-index: 2;
}

.flex {
  display: flex;
}

.topdiv {
  flex-direction: row;
  justify-self: center;
  align-self: center;
  justify-content: space-around;
  max-width: 1400px;
}

.picdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: auto;
  max-height: auto;
}

.pic {
  background-color: var(--background);
  max-width: 80%;
  max-height: 80%;
  border-radius: 2em;
  box-shadow: 0 0 1em 0.5em var(--border);
  border: var(--text-primary) 0.1em solid;
}

.shimmer {
  margin: 0;
  color: var(--text-primary);
  display: inline-block;
  -webkit-mask: var(--background-shimmer) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 3s infinite;
  font-size: 4em;
  /* max-width: fit-content; */
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.details {
  margin: 1em 0 0 0;
  letter-spacing: 0.05em;
  line-height: 1.9em;
  background-color: var(--background);
  border-radius: 1em;
  padding: 1em;
  box-shadow: 2rem 2rem 5rem rgba(0, 0, 0, 0.5);
}

.detaildiv {
  display: flex;
  align-items: center;
  margin: 3em;
}

.separator {
  width: 0.5em;
}

.socialicon:hover {
  background-color: var(--accent);
  border-radius: 50%;
  box-shadow: 0 0 10px var(--accent);
}

@media only screen and (max-width: 675px) {
  .topdiv {
    flex-direction: column;
  }
  .pic {
    max-width: 75%;
    height: 60%;
  }

  .detaildiv {
    display: flex;
    align-items: center;
    margin-top: 1em;
    text-align: center;
  }

  .links {
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }
  .outerShimmer {
    background-color: var(--background);
    border-radius: 1em;
    padding: 1em;
    max-width: fit-content;
  }
  .moep {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
