.imprint {
  z-index: 3;
  white-space: pre-wrap;
  margin: 4em 0;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  height: calc(100vh - 8rem);
}

.imprintField {
  display: flex;
  /* flex-direction: column; */
  justify-self: center;
  align-self: center;
  /* height: calc(100% - 4rem); */
}

.imprintLegend {
  color: var(--text-secondary);
}

.imprintOuter {
  width: 65vw;
  max-width: 1000px;
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imprintDiv {
  flex-direction: column;
  background-color: var(--background);
  border-radius: 1em;
  padding: 1em;
  margin-bottom: 1em;
}

.imprintButton {
  align-self: center;
  width: fit-content;
  margin: 2em;
  color: var(--accent);
  background-color: var(--text-secondary);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: large;
  letter-spacing: 0.05em;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid var(--border);
  padding: 5px 15px;
  box-shadow: 0 0 10px 1px var(--text-primary);
  cursor: pointer;
}

.imprintButton:hover {
  color: var(--text-primary);
  background-color: var(--accent);
}
