.outerNav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  z-index: 5;
  position: fixed;
  top: 0;
  height: 4em;
  background-color: var(--background-secondary);
  border-width: 0px 0px 1px 0px;
  border-color: var(--text-secondary);
  border-style: solid;
  z-index: 100;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--background-secondary);
  color: var(--text-primary);
  font-size: 1em;
  position: sticky;
  width: 100vw;
  max-width: 1400px;
  /* opacity: 90%; */
  z-index: 10;
}

.logo {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  justify-items: center;
}

.imgNavbar {
  height: 2em;
  -webkit-filter: invert(0.75); /* safari 6.0 - 9.0 */
  filter: invert(0.75);
  align-self: flex-end;
  justify-self: end;
  align-items: end;
  align-content: end;
  text-align: end;
}

.placeholder {
  width: 28vw;
}

.lang {
  height: 20px;
  width: 30px;
  margin: 0 0 10px;
  cursor: pointer;
}

.navLinks {
  font-style: italic;
  font-size: 1.5em;
  padding: 2px 10px;
  color: var(--text-primary);
  text-decoration: none;
  position: relative;
  width: fit-content;
  height: fit-content;

  align-self: center;
}

.navLinks:hover,
.logo:hover {
  color: var(--text-navbar);
  text-decoration: none;
  border-radius: 1em;
  box-shadow: 0 0 50px var(--accent);
  transition-delay: 0.5s;
  cursor: pointer;
}

.navhr {
  margin: 0 auto;

  margin-top: 0.5em;
  border: 0;
  height: 2px;
}

.hrwhite {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

.hrblack {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.underline {
  position: relative;
  color: var(--text-primary);
  text-decoration: none;
}

.underline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    var(--dash-out),
    var(--dash),
    var(--dash-out)
  );
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}

.underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* darkmode */

.navSwitches {
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#toggledark {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--accent);
  border-radius: 1em;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  box-shadow: 0 0 0.5em var(--border);
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: var(--text-primary);
  border-radius: 8px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: var(--background);
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.active {
  color: var(--text-navbar);
}

.hamburger {
  position: absolute;
  width: 3em;
  right: 0;
  background-color: var(--background);
  color: var(--text-primary);
  transition: all 0.3s ease-in;
  overflow: hidden;
}

.hamburgersvg {
  position: absolute;
  width: 3em;
  right: 1em;
  top: 1em;
  visibility: hidden;
}

.hamburgersvg path {
  stroke: var(--text-primary);
  stroke-width: 1px;
  fill: var(--text-primary);
}

.nav-elements {
  display: flex;
  height: fit-content;
  padding: 1em;
  border-radius: 1em;
}

@media only screen and (max-width: 525px) {
  .hamburgersvg {
    visibility: visible;
    cursor: pointer;
  }

  .logo {
    visibility: hidden;
  }

  .nav-elements {
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 4em;
    background-color: var(--background-secondary);

    transition: all 0.3s ease-in;
    width: fit-content;
    transform: scaleX(0);
  }

  .nav-elements.active {
    transform: scaleX(1);
  }

  .navSwitches {
    margin-top: 1em;
  }
}
