#projects {
  display: flex;
  text-align: center;
  justify-content: space-around;
  z-index: 2;
}

.projects {
  padding: 3em;
  margin: 3em 0;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  border-radius: 1em;
  box-shadow: 2rem 2rem 5rem rgba(0, 0, 0, 0.5);
}

.projectshr {
  align-self: center;
}

.projectList {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.projectPic {
  max-height: 100%;
  max-width: fit-content;
  overflow: hidden;
  object-position: center;
}

.askText {
  left: 50%;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 65%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  width: 100%;
}

.alink {
  margin: 2rem;
}
.projectCard {
  border-radius: 1em;
  width: 20em;
  height: 20em;
  overflow: hidden;
  position: relative;
}
.projectList a {
  width: fit-content;
}

.projectCard::before {
  background: var(--gradient);
  background-image: var(--gradient);
  content: "";
  height: 100%;
  width: 100%;
  opacity: 0.9;
  position: absolute;
  transition: 0.4s ease-in-out;
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

.projectCard:hover::before {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.projectText {
  color: ghostwhite;
  width: 100%;
  height: 100%;
  top: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  transition: 0.5s ease-in-out;
}

.projectCard:hover > .projectText {
  opacity: 1;
  top: 30%;
}

.projectP {
  margin: 1em 1em;
  font-style: italic;
}

/* .projectPic {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.projectPic:hover {
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
} */

@media only screen and (max-width: 850px) {
  .projectList {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .projectCard {
    width: 15em;
    height: 15em;
    margin: 2em 1em;
  }
  .projects {
    padding: 1.5em;
  }
}

@media only screen and (max-width: 500px) {
  .projects {
    padding: 1em;
  }
}
