.bottom-arrow {
  border-bottom: 2px solid var(--text-secondary);
  z-index: 5;
  margin-bottom: 3em;
}

.bottom-arrow:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 3em solid var(--text-secondary);
  border-left: 3em solid transparent;
  border-right: 3em solid transparent;
}
