#skills {
  display: flex;
  background-color: var(--background);
  text-align: center;
  justify-content: space-around;
  z-index: 2;
}

.skills {
  margin-top: 2em;
  padding-top: 3em;
  flex-direction: column;
  align-self: center;
  max-width: 1400px;
  display: flex;
}

.skillshr {
  align-self: center;
}

p {
  margin: 0;
}

.skillstable {
  font-size: large;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  margin: 0 3rem 2rem;
}

.skillstable .tilter {
  position: relative;
  max-width: fit-content;
  margin: 2rem;
  box-shadow: 2rem 2rem 5rem rgba(0, 0, 0, 0.5);
  border-radius: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

/* .margintop {
  margin: 2em 0;
} */

.skillIcons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 5em;
  margin: 2em;
  object-fit: contain;
}

.invert {
  filter: invert(0.2);
}

@media only screen and (max-width: 675px) {
  .skillIcons {
    margin: 1em;
  }
}
